<template>
  <div
    style="display: inline-block;font-weight: normal"
  >
    <click-to-edit
      v-model="scope.row.scale.date_start"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.date_start')"
      placeholder="Дата с"
      style="display: inline-block"
      type="date-picker"
      :date-picker-options="{firstDayOfWeek: 1}"
      @input="saveElementPropChange(scope.row, 'scale', {date_start:$event})"
    ></click-to-edit>
    <click-to-edit
      v-model="scope.row.scale.date_end"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.date_end')"
      placeholder="Дата по"
      style="display: inline-block"
      type="date-picker"
      :date-picker-options="{firstDayOfWeek: 1}"
      @input="saveElementPropChange(scope.row, 'scale', {date_end:$event})"
    ></click-to-edit>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";

export default {
  name: 'element-point-chief-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {}
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
